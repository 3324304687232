import {darken, COLORS} from 'core/styles';
import styled, {css} from 'styled-components';

export function getLabelStyles(props) {
    const baseStyles = {
        fontSize: '14px',
        display: 'block',
        marginBottom: '10px',
        fontWeight: 500,
        lineHeight: '16px',
        position: 'relative',
    };

    if (props.required) {
        return Object.assign(
            baseStyles,
            {
                left: '10px',

                '&::before': {
                    content: '"*"',
                    position: 'absolute',
                    left: '-10px',
                    top: '-2px',
                    fontSize: '14px',
                    color: darken(COLORS.primary, 15),
                },
            },
            props.style,
        );
    }

    return Object.assign(baseStyles, props.style);
}

export const LabelStyledElem = styled.label`
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    lineHeight: 16px;
    position: relative;
    ${(props) => (props.$css ? props.$css : '')};
`;
