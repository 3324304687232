import {bp, Z, HEADER_HEIGHT, SIDEBAR_WIDTH, COLORS, newCOLORS} from 'core/styles';
import styled from 'styled-components';
import {css} from 'styled-components';
import Link from 'next/link';

export const SidebarModalCss = css`
    background-color: ${COLORS.grayDark};
    z-index: ${Z.sidebar};
    position: fixed;
    top: ${HEADER_HEIGHT};
    left: ${(props) => (props.sidebarActive ? '0' : '-75%')};
    bottom: 0;
    width: 75%;
    padding: 15px;
    transition: left 150ms linear;
    ${bp('md')} {
        transition: none;
        width:  ${SIDEBAR_WIDTH};
        left: 0;
        top: 0;
    }
}`;

export const SidebarContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background-color: ${newCOLORS.gray100};
    border-right: 1px solid ${newCOLORS.gray300};
    padding: 0 24px 40px 24px;
    overflow: auto;

    ${bp('md')} {
        padding: 30px 24px;
        z-index: ${Z.sidebar};
        width: ${SIDEBAR_WIDTH};
    }
`;

export const SidebarHeaderLink = styled(Link)`
    display: none;
    position: relative;
    z-index: 100;
    color: ${newCOLORS.primary300};
    margin: 0;
    font-size: 34px;
    height: 36px;
    text-decoration: none;
    text-transform: lowercase;
    font-weight: bold;
    ${bp('md')} {
        display: block;
    }

    svg {
        width: 235px;
        height: 36px;
    }
`;
