'use client';
import React from 'react';
import {
    BackgroundImageContainer,
    RightContainer,
    RightInner,
    LogoContainer,
    LoginContainer,
    LoginInner,
} from './styles';
import Logo from 'assets/img/logo.svg';
import backgroundImage from 'assets/img/cr-login-art.png';
import Image from 'next/image';

const Login = (props) => {
    return (
        <LoginContainer>
            <BackgroundImageContainer>
                <Image
                    fill
                    priority
                    alt="Login Background"
                    src={backgroundImage}
                    sizes="100%"
                    style={{
                        objectFit: 'contain',
                    }}
                />
            </BackgroundImageContainer>
            <RightContainer>
            <RightInner>
                <LogoContainer>
                    <div style={{width: '235px'}}><Logo /></div>
                    <div className="text-xs">An American Addiction Centers Product</div>
                </LogoContainer>
                <LoginInner>{props.children}</LoginInner>
            </RightInner>
            </RightContainer>
        </LoginContainer>
    );
};

export default Login;
