'use client';
import {AppProgressBar} from 'next-nprogress-bar';

const ProgressBarProvider = ({children}) => {
    return (
        <>
            {children}
            <AppProgressBar />
        </>
    );
};

export default ProgressBarProvider;
