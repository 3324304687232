'use client';
import styled from 'styled-components';
import {HEADER_HEIGHT, Z, bp, newCOLORS} from 'core/styles';
import MenuHamburgerButton from 'assets/img/icons/hamburger.svg';
import CloseXButton from 'assets/img/icons/close_menu_white.svg';

export let HeaderContainer = styled.header`
    background-color: ${newCOLORS.primary300};
    height: ${HEADER_HEIGHT};
    z-index: ${Z.navigation};
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;

    ${bp('md')} {
        background-color: #fff;
        position: relative;
        display: none;
    }
`;

export let ButtonContainer = styled.div`
    position: absolute;
    cursor: pointer;
    top: 10px;
    left: 12px;
    width: 35px;
    height: 35px;
    padding-left: 10px, ${bp('md')} {
        display: none;
    }
`;

export let CloseButton = styled(CloseXButton)`
    cursor: pointer;
    position: relative;
`;

export let MenuButton = styled(MenuHamburgerButton)`
    height: 100%;
    width: 100%;
`;
