'use client'
import {COLORS, darken} from 'core/styles';
import styled from 'styled-components';

export let container = (props) => {
    return {
        cursor: 'pointer',
        color: COLORS.grayFont,
        display: 'inline-block',
        marginRight: '15px',
        fontSize: '14px',
        fontWeight: 'bold',
        textDecoration: 'underline',
        padding: '10px 20px',
        verticalAlign: 'middle',
        opacity: props.submitting ? 0.5 : 1,

        '&:hover': {
            color: COLORS.danger,
        },

        '&:active': {
            color: darken(COLORS.danger, 5),
        },
    };
};

export const Container = styled.div`
    cursor: pointer;
    color: ${COLORS.grayFont};
    display: inline-block;
    margin-right: 15px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    text-decoration: underline;
    padding: 10px 20px;
    vertical-align: middle;
    opacity: ${(props) => (props.submitting ? 0.5 : 1)};

    &:hover {
        color: ${COLORS.danger};
    }

    &:active {
        color: ${darken(COLORS.danger, 5)};
    }
`;
