import {bp} from 'core/styles';
import styled from 'styled-components';

export const BackgroundImageContainer = styled.div`
    display: none;

    ${bp('lg')} {
        display: block;
        position: relative;
        flex: 1;
        background: #e9f4fe;
    }
`;

export const RightContainer = styled.div`
    flex: 1;
    margin: auto;
    max-width: 100%;
    padding: 24px;
`;

export const RightInner = styled.div`
    max-width: 100%;
    width: 345px;
    margin: auto;
`;

export const LogoContainer = styled.div`
    max-width: 100%;
    width: 345px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 80px;
`;

export let LoginContainer = styled.div`
    display: flex;
    height: 100vh;
`;

export let LoginInner = styled.div`
    width: ;
`;
