import React from 'react';
import propTypes from 'prop-types';

export default function IconCreditCard({
    width = '24',
    height = '24',
    fill = '#000',
    rotate = '0',
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            style={{transform: `rotate(${rotate}deg)`}}>
            <path
                d="M20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20ZM4 12V18H20V12H4ZM4 6V8H20V6H4ZM13 16H6V14H13V16Z"
                fill={fill}
            />
        </svg>
    );
}

IconCreditCard.propTypes = {
    width: propTypes.string,
    height: propTypes.string,
    fill: propTypes.string,
    rotate: propTypes.string,
};
