import {COLORS, lighten} from 'core/styles';
import styled, {css} from 'styled-components';
import Link from 'next/link';

export let ModalTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
`;

export let ModalDivider = styled.hr`
    border-top: 1px solid ${lighten(COLORS.grayFont, 10)};
    width: 200px;
`;

export let ModalListingName = styled.div`
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
`;

export let ModalPackageDetails = styled.div`
    font-size: 16px;
    width: 425px;
    padding: 24px 40px;
    border-radius: 16px;
    background: #e9f4fe;
    text-align: left;
    margin: 24px auto;

    li {
        margin-left: 12px;
        list-style: none;
    }
    li:first-child {
        margin-top: 12px;
    }
    li:last-child {
        margin-bottom: 12px;
    }
`;

export let ModalPackageSteps = styled.div`
    text-align: left;
    width: 510px;
    margin: 0 auto;
    li {
        margin-bottom: 12px;
    }
`;

export const ErrorStyle = styled.div`
    color: ${COLORS.danger};
`;

export const ListingTypeSelect = css`
    display: inline-block;
    max-width: 210px;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 1px solid ${COLORS.primary};
    border-radius: 5px;
`;

export const CardLink = styled(Link)`
    font-weight: bold;
    color: ${COLORS.primary};
    text-decoration: underline;
    white-space: nowrap;
`;

