import React from 'react';
import {LabelStyledElem} from './styles';
import {newCOLORS} from 'core/styles';

const LabelStyled = (props) => (
    <LabelStyledElem
        required={props.required}
        $css={props.$css}
        className={props.className}
        htmlFor={props.htmlFor}>
        {props.children}
        {props.required && <span style={{color: newCOLORS.warning}}>*</span>}
    </LabelStyledElem>
);

export default LabelStyled;
