import React, {useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {hideModal} from 'features/modal/slice';

import * as styles from './styles';
import {
    Aligner,
    Background,
    Body,
    CloseIconStyled,
    Container,
    Header,
    Outer,
    Wrapper,
} from './styles';

let ModalWrapperStyled = (props) => {
    const myRef = useRef();
    const handleClose = () => {
        if (props.handleClose) {
            props.handleClose();
            return;
        }

        props.hideModal();
    };

    const handleClick = (e) => {
        if (e.key && e.key !== 'Escape') return;
        if (myRef && myRef.current && myRef.current.contains(e.target)) return;
        handleClose();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        document.addEventListener('keydown', handleClick, false);
        window.addEventListener('popstate', handleClose, false);
        return () => {
            document.removeEventListener('mousedown', handleClick, false);
            document.removeEventListener('keydown', handleClick, false);
            window.removeEventListener('popstate', handleClose, false);
        };
    }, []);

    return (
        <Container>
            <Background />
            <Aligner />
            <Outer>
                <Wrapper $css={props.$css} className={props?.className} ref={myRef}>
                    <Header>
                        <CloseIconStyled onClick={handleClose} />
                    </Header>
                    <Body>{props.children}</Body>
                </Wrapper>
            </Outer>
        </Container>
    );
};

export default connect(null, {
    hideModal,
})(ModalWrapperStyled);
