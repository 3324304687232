import React, {useState} from 'react';
import {StyledSidebarSettings, StyledSettingsHeader, StyledLinks} from './styles';
import {useParams, usePathname} from 'next/navigation';
import {newCOLORS} from 'core/styles';
import IconChevron from 'features/icons/IconChevron';
import Authorize from 'features/auth/components/Auth/Auth';
import Link from 'next/link';
import {canSubaccount} from 'features/auth/components/AuthRoute/AuthRoute';

const SidebarSettings = ({
    Icon,
    text,
    userAccountType,
    accountId,
    userId,
    userPermissions,
    handleLogout,
    onClick,
}) => {
    const pathname = usePathname();
    const params = useParams();
    const accountsHref = '/accounts';
    const manageAccountHref =
        userAccountType === 'marketing'
            ? `/account/${accountId}/subaccounts`
            : `/account/${accountId}`;
    const editProfileHref = `/users/${userId}/edit`;
    const accountUsersHref = `/account/${accountId}/users`;
    const createAccountUserHref = `/account/${accountId}/users/create`;
    const editAccountUserHref = params?.userId ? `/users/${params?.userId}/edit` : '';
    const rolesHref = '/roles';

    const allLinks = [
        manageAccountHref,
        `${manageAccountHref}/edit`,
        accountsHref,
        editProfileHref,
        rolesHref,
        accountUsersHref,
        createAccountUserHref,
    ];
    if (editAccountUserHref) {
        allLinks.push(editAccountUserHref);
    }

    const isMenuActive = allLinks.includes(pathname);

    const [expand, setExpand] = useState(isMenuActive);

    return (
        <StyledSidebarSettings isMenuActive={isMenuActive} expand={expand}>
            <StyledSettingsHeader
                isMenuActive={isMenuActive}
                expand={expand}
                onClick={() => {
                    setExpand(!expand);
                }}>
                {Icon && (
                    <Icon
                        fill={`${
                            isMenuActive ? newCOLORS.primary300 : newCOLORS.gray400
                        }`}
                    />
                )}
                <div className="title text-xl">{text}</div>
                <IconChevron
                    fill={`${isMenuActive ? newCOLORS.primary300 : newCOLORS.gray400}`}
                    rotate={expand ? '360' : '270'}
                />
            </StyledSettingsHeader>
            {expand && (
                <StyledLinks isMenuActive={isMenuActive}>
                    <Authorize permissions={['account-view-all']}>
                        <Link
                            className={`text-lg link ${
                                pathname === accountsHref ? 'active' : ''
                            }`}
                            href={accountsHref}
                            onClick={onClick}>
                            Manage Accounts
                        </Link>
                    </Authorize>
                    {/* hide single account page link to internal admins since they can manage other accounts under the Manage Accounts link */}
                    {!userPermissions?.includes('account-view-all') && (
                        <Authorize permissions={['account-view-own']}>
                            <Link
                                className={`text-lg link ${
                                    pathname === manageAccountHref ||
                                    pathname === `${manageAccountHref}/edit`
                                        ? 'active'
                                        : ''
                                }`}
                                href={manageAccountHref}
                                onClick={onClick}>
                                Manage Account
                            </Link>
                        </Authorize>
                    )}
                    <Authorize permissions={['role-view-all']}>
                        <Link
                            className={`text-lg link ${
                                pathname === rolesHref ? 'active' : ''
                            }`}
                            href={rolesHref}
                            onClick={onClick}>
                            Permissions & Roles
                        </Link>
                    </Authorize>
                    <Link
                        className={`text-lg link ${
                            pathname === editProfileHref ? 'active' : ''
                        }`}
                        href={editProfileHref}
                        onClick={onClick}>
                        Edit Profile
                    </Link>
                    <Authorize
                        authorize={(user, account) =>
                            user.permissions.includes('user-view-all') ||
                            canSubaccount(user, account) ||
                            (user.account_id === accountId &&
                                user.permissions.includes('user-view-own'))
                        }>
                        <Link
                            className={`text-lg link ${
                                pathname !== editProfileHref &&
                                (pathname === accountUsersHref ||
                                    pathname === editAccountUserHref ||
                                    pathname === createAccountUserHref)
                                    ? 'active'
                                    : ''
                            }`}
                            href={accountUsersHref}
                            onClick={onClick}>
                            Users
                        </Link>
                    </Authorize>
                    <div className="text-lg link" onClick={handleLogout}>
                        Sign Out
                    </div>
                </StyledLinks>
            )}
        </StyledSidebarSettings>
    );
};

export default SidebarSettings;
