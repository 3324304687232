import {COLORS, Z} from 'core/styles';
import styled from 'styled-components';
import {css} from 'styled-components';
import CloseIcon from 'assets/img/icons/close_menu.svg';

export let Container = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${Z.modal};
`;

export let Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.35;
`;

export let Aligner = styled.div`
    text-align: center;
    white-space: nowrap;
    display: inline-block;
    height: 100%;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -1px;
    }
`;

export let Outer = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 100%;
`;

export const Wrapper = styled.div`
    max-width: 100%;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    position: relative;
    background: #fff;
    overflow: auto;
    max-height: 95vh;
    border-radius: 16px;
    ${(props) => (props.$css ? props.$css : '')};
`;

export let Header = styled.div`
`;

export let CloseIconStyled = styled(CloseIcon)`
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    width: 25px;
    height: 25px;

    & #close {
        transform: scale(1.5) translate(-4px, -4px);
    }
`;

export let Body = styled.div`
    background-color: #fff;
    padding: 44px;
`;
