'use client';
import React from 'react';

import Loading from 'features/generic/components/Loading/Loading';
import CancelButton from 'features/form/components/CancelButton/CancelButton';
import ModalWrapper from 'features/modal/components/ModalWrapper/ModalWrapper';
import SubmitButton from 'features/form/components/SubmitButton/SubmitButton';
import WarningIcon from 'assets/img/icons/alert.svg';

import * as styles from './styles';
import {formatListingTypeDisplayName} from 'utils/helpers';

const ChangeListingTypeForm = (props) => {
    return (
        <ModalWrapper>
            <div style={styles.container}>
                {props.error && <div style={styles.error}>{props.error}</div>}
                <WarningIcon style={styles.warning} />
                {props.includeVipWarning && (
                    <div style={styles.message}>
                        All active VIP State subscriptions will also be canceled.
                    </div>
                )}
                {props.includeDowngradeWarning && (
                    <div style={styles.message}>
                        Please confirm the changes made to the listing type for
                        <br />
                        <b style={styles.reason}>{props.listing.listing_name}</b>:
                        <br />
                        <br />
                        <div style={{marginBottom: '16px'}}>
                            Current Type:<br />
                            <b style={styles.reason}>
                                {formatListingTypeDisplayName(props.fromType)}
                            </b>
                        </div>
                        <div>
                            Changed Type:<br />
                            <b style={styles.reason}>
                                {formatListingTypeDisplayName(props.toType)}
                            </b>
                        </div>
                    </div>
                )}
                {!props.isSaving && (
                    <SubmitButton handleClick={props.handleSubmit} submitText="Confirm" />
                )}
                {props.isSaving && <Loading style={styles.loading} />}
                <CancelButton handleClick={props.handleClose} />
            </div>
        </ModalWrapper>
    );
};

export default ChangeListingTypeForm;
