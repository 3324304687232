import React, {useState} from 'react';
import {
    Container,
    UserName,
    UserRole,
    Account,
    SubaccountsList,
    AccountLink,
} from './styles';
import IconChevron from 'features/icons/IconChevron';
import {newCOLORS} from 'core/styles';
import {useParams, usePathname} from 'next/navigation';

const UserPanelHeader = (props) => {
    const {primaryAccount = {}} = props;
    const [expand, setExpand] = useState(false);
    if (!props.user) return <span />;

    const pathname = usePathname();
    const params = useParams();
    const paths = ['/listings', '/reviews', '/billing', '/users'];
    const matchedPath = params?.listingId
        ? '/listings'
        : paths.find((path) => pathname.includes(path)) || '/subaccounts';

    const hasSubaccounts =
        Array.isArray(primaryAccount?.subaccounts) &&
        primaryAccount?.subaccounts?.length > 0 &&
        props?.user?.permissions?.includes('account-manage-subaccounts');
    const activeSubaccount =
        hasSubaccounts &&
        (primaryAccount?.subaccounts?.find(
            (subaccount) => subaccount.account_id === props.accountId,
        ) ||
            (params?.listingId &&
                primaryAccount?.subaccounts?.find((subaccount) =>
                    subaccount.listings.find(
                        (listingId) => listingId === params?.listingId,
                    ),
                )));

    return (
        <Container>
            <UserName className="text-xl">
                {props.user.first_name} {props.user.last_name}
            </UserName>
            <UserRole className="text-base">{props.user.role_name}</UserRole>
            <Account
                hasSubaccounts={hasSubaccounts}
                className="text-lg"
                onClick={() => setExpand(!expand)}>
                {activeSubaccount?.organization_name || primaryAccount?.organization_name}
                {hasSubaccounts && (
                    <IconChevron
                        fill={newCOLORS.gray400}
                        rotate={expand ? '360' : '270'}
                    />
                )}
            </Account>
            {expand && hasSubaccounts && (
                <SubaccountsList>
                    <AccountLink
                        isActive={
                            !activeSubaccount &&
                            props.accountId === primaryAccount?.account_id
                        }
                        href={`/account/${primaryAccount?.account_id}${matchedPath}`}>
                        {primaryAccount?.organization_name}
                    </AccountLink>
                    {primaryAccount?.subaccounts?.map((subaccount) => (
                        <AccountLink
                            key={subaccount.account_id}
                            isActive={
                                props.accountId === subaccount.account_id ||
                                activeSubaccount?.account_id === subaccount.account_id
                            }
                            href={`/account/${subaccount.account_id}${matchedPath}`}>
                            {subaccount.organization_name}
                        </AccountLink>
                    ))}
                </SubaccountsList>
            )}
        </Container>
    );
};

export default UserPanelHeader;
