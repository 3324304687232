import React, {useState, useEffect} from 'react';
import ModalWrapperStyled from 'features/modal/components/ModalWrapperStyled/ModalWrapperStyled';
import {
    ModalTitle,
    ModalListingName,
    ModalPackageDetails,
    ModalPackageSteps,
    ErrorStyle,
    ListingTypeSelect,
    CardLink,
} from './styles';
import {LISTING_TYPES, LISTING_TYPES_PAID} from 'core/constants';
import {
    capitalCase,
    get,
    getListingType,
    formatListingTypeDisplayName,
} from 'utils/helpers';
import Authorize from 'features/auth/components/Auth/Auth';
import {SelectStyled} from '../../../form/components/DropdownInput/styles';
import SubmitButtonStyled from '../../../form/components/SubmitButtonStyled/SubmitButtonStyled';
import {selectHasValidCard} from 'features/billing/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {editListing} from 'features/listings/actions';
import {hideModal, showModal} from 'features/modal/slice';
import {getListingSubscriptions} from 'features/vip/actions';
import {notifySuccessWithTimeout} from 'features/notification/slice';
import {SubmissionError} from 'redux-form';
import {getCardsIfNeeded} from 'features/billing/actions';

const UpgradeListingModal = ({listing, toType}, props) => {
    const dispatch = useDispatch();
    const listingType = toType || getListingType(listing);
    const hasValidCard = useSelector((state) => selectHasValidCard(state, listing));

    const [listingPackage, setListingPackage] = useState(listingType);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        dispatch(getCardsIfNeeded(listing.account_id));
    }, []);

    const requirementsFulfilled = (listing) => {
        if (LISTING_TYPES_PAID.includes(listingPackage)) {
            return (
                listing.phones?.[0]?.number &&
                listing.call_tracking_number &&
                listing.website_url &&
                listing.photos?.length &&
                listing.treatment_philosophies?.length &&
                listing.highlights?.length &&
                listing.specializations?.length &&
                listing.tags?.environmental_setting?.length &&
                hasValidCard
            );
        }
        return true;
    };

    const conditionalUpgradeListingType = (listing, listingPackage) => {
        if (!requirementsFulfilled(listing)) {
            return;
        }

        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);

        const currentListingType = getListingType(listing);
        const fromPaid =
            LISTING_TYPES_PAID.includes(currentListingType) &&
            (listingPackage === 'claimed' || listingPackage === 'free');

        if (fromPaid) {
            return dispatch(
                showModal({
                    type: 'CHANGE_LISTING_TYPE',
                    props: {
                        listing,
                        type: listingPackage,
                    },
                }),
            );
        }

        dispatch(editListing({listing_id: listing.listing_id, type: listingPackage}))
            .then((data) => {
                if (
                    currentListingType === 'vip' &&
                    listingPackage &&
                    listingPackage !== 'vip'
                ) {
                    dispatch(getListingSubscriptions(listing.listing_id));
                }
                dispatch(notifySuccessWithTimeout(data.message));
                dispatch(hideModal());
            })
            .catch((e) => {
                const error = e && e.message ? e.message : 'Could not edit listing type';
                return Promise.reject(new SubmissionError({_error: error}));
            });
    };

    const getLink = (href = '', text = '') => {
        return (
            <span onClick={() => dispatch(hideModal())}>
                <CardLink href={href}>{text}</CardLink>
            </span>
        );
    };

    return (
        <ModalWrapperStyled>
            <ModalTitle>Change Your Listing Package</ModalTitle>
            <ModalListingName>Package Details:</ModalListingName>
            <ModalPackageDetails>
                <li>
                    <b>Free</b>: $0/Month
                </li>
                <li>
                    <b>Claimed</b>: $0/Month
                </li>
                <br />
                <li>
                    <b>Standard</b>: $1000/Month
                </li>
                <li>
                    <b>Standard (Outpatient only)</b>: $500/Month
                </li>
                <br />
                <li>
                    <b>Promoted</b>: $2000/Month
                </li>
                <li>
                    <b>Promoted (Outpatient only)</b>: $1000/Month
                </li>
                <br />
                <li>
                    <b>VIP</b>: $3000/Month
                </li>
                <li>
                    <b>VIP (Outpatient only)</b>: $1500/Month
                </li>
            </ModalPackageDetails>
            Each package bills on the 1st of the Month. Upgrading mid-month will charge a
            prorated amount.
            <br />
            <br />
            <Authorize
                authorize={(user) =>
                    user.permissions.includes('listing-edit-all-type') ||
                    (listing.parent_account_id === user.account_id &&
                        user.permissions.includes('account-manage-subaccounts')) ||
                    (listing.account_id === user.account_id &&
                        user.permissions.includes('listing-edit-own-type'))
                }>
                <span className="text-lg">To change your package,</span>
                <ModalPackageSteps>
                    <ol>
                        <li>
                            Select a package: &nbsp;
                            <SelectStyled
                                $css={ListingTypeSelect}
                                name="type"
                                label=""
                                value={listingPackage}
                                onChange={(e) => setListingPackage(e.target.value)}>
                                {LISTING_TYPES.map((type) => (
                                    <option key={type} value={type}>
                                        {formatListingTypeDisplayName(type)}
                                    </option>
                                ))}
                            </SelectStyled>
                        </li>
                        {LISTING_TYPES_PAID.includes(listingPackage) && (
                            <>
                                {!listing.phones?.[0]?.number && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure &quot;Local Phone&quot; is added
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!listing.call_tracking_number && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure &quot;Tracking Number&quot; is added
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!listing.website_url && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure &quot;Website&quot; is added
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!listing.photos?.length && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure the listing has a photo
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!listing.treatment_philosophies?.length && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure &quot;About Facility&quot; is completed
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!listing.highlights?.length && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure &quot;Facility Highlights&quot; is
                                            completed
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!listing.specializations?.length && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure &quot;Areas of Specialization&quot; is
                                            completed
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!listing.tags?.environmental_setting?.length && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure &quot;Environmental Setting&quot; is
                                            completed
                                        </ErrorStyle>
                                    </li>
                                )}
                                {!hasValidCard && (
                                    <li>
                                        <ErrorStyle>
                                            Ensure that your account has a valid primary
                                            credit card
                                            <Authorize
                                                authorize={(user) =>
                                                    user.permissions.includes(
                                                        'creditCard-create-all',
                                                    ) ||
                                                    (listing.parent_account_id ===
                                                        user.account_id &&
                                                        user.permissions.includes(
                                                            'account-manage-subaccounts',
                                                        )) ||
                                                    (user.account_id ===
                                                        listing.account_id &&
                                                        user.permissions.includes(
                                                            'creditCard-create-own',
                                                        ))
                                                }>
                                                <span>
                                                    {' '}
                                                    in{' '}
                                                    {getLink(
                                                        `/account/${listing.account_id}/billing`,
                                                        'Manage cards',
                                                    )}
                                                </span>
                                            </Authorize>
                                        </ErrorStyle>
                                    </li>
                                )}
                            </>
                        )}

                        <li>
                            Click{' '}
                            <SubmitButtonStyled
                                submitting={isSubmitting}
                                handleClick={() =>
                                    conditionalUpgradeListingType(listing, listingPackage)
                                }
                                disabled={
                                    !requirementsFulfilled(listing) ||
                                    (toType
                                        ? getListingType(listing) === listingPackage
                                        : listingType === listingPackage)
                                }
                                submitText="Submit"
                                submittingText="Submitting..."
                                successText="Submitted!"
                            />
                        </li>
                    </ol>
                </ModalPackageSteps>
            </Authorize>
            {(listingType === 'free' || listingType === 'claimed') && (
                <span>
                    Please reach out to upgrade your listing{' '}
                    {getLink('/support', 'here.')}
                </span>
            )}
        </ModalWrapperStyled>
    );
};

export default UpgradeListingModal;
