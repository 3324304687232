import React from 'react';
import {StyledLink} from './styles';
import {usePathname} from 'next/navigation';
import {newCOLORS} from 'core/styles';

const SidebarLink = ({text, Icon, onClick, href, alternativeHref}) => {
    const pathname = usePathname();
    const isActive = pathname === href || pathname === alternativeHref;

    return (
        <StyledLink
            className={`${isActive ? 'active' : ''}`}
            href={href || '/'}
            onClick={onClick}>
            {Icon && (
                <Icon fill={`${isActive ? newCOLORS.primary300 : newCOLORS.gray400}`} />
            )}
            <div className="link-text text-xl">{text}</div>
        </StyledLink>
    );
};

export default SidebarLink;
