import {newCOLORS} from 'core/styles';
import styled from 'styled-components';

export const StyledSidebarSettings = styled.div`
    background-color: ${(props) => (props.isMenuActive ? newCOLORS.primary : 'inherit')};
    border-radius: 8px;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: ${(props) =>
            props.isMenuActive
                ? newCOLORS.primary
                : props.expand
                ? 'inherit'
                : newCOLORS.gray200};
    }
`;

export const StyledSettingsHeader = styled.div`
    display: flex;
    padding: 12px 8px;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    .title {
        color: ${(props) =>
            props.isMenuActive ? newCOLORS.primary300 : newCOLORS.gray400};
        font-weight: 500;
        text-decoration: none;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        margin-left: 12px;
    }

    .title + svg {
        margin-left: auto;
    }
`;

export const StyledLinks = styled.div`
    .link {
        color: ${newCOLORS.gray400};
        display: block;
        font-weight: 500;
        padding: 8px 12px;
        border-radius: 8px;
        transition: background-color 0.5s ease;

        &:hover {
            cursor: pointer;
            background-color: ${(props) =>
                props.isMenuActive ? newCOLORS.gray200 : newCOLORS.gray200};
        }

        &.active {
            color: ${newCOLORS.primary300};

            &:hover {
                background: inherit;
            }
        }
    }
`;
