'use client';
import {
    lighten,
    COLORS,
    Z,
    HEADER_HEIGHT,
    bp,
    SIDEBAR_WIDTH,
    MAX_WIDTH_WITH_PADDING,
    MAX_WIDTH,
    MONO,
    NOTIFICATION_WIDTH,
    NOTIFICATION_HEIGHT,
} from 'core/styles';
import styled from 'styled-components';

export let NotificationContainer = styled.div`
    height: ${HEADER_HEIGHT};
    z-index: ${Z.modal};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: ${MAX_WIDTH_WITH_PADDING};
    pointer-events: none;

    ${bp('md')} {
        //zIndex: Z.navigation;
        height: 30px;
        margin-left: ${SIDEBAR_WIDTH};
    }
`;

export const notification = (props) => {
    const {success, error, updateAvailable} = props;

    let styles = {
        pointerEvents: 'all',
        textAlign: 'left',
        backgroundColor: COLORS.grayLight,
        minHeight: NOTIFICATION_HEIGHT,
        maxHeight: 'fit-content',
        margin: '10px 15px 0 auto',
        borderRadius: '4px',
        minWidth: NOTIFICATION_WIDTH,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        padding: '8px',
        maxWidth: NOTIFICATION_WIDTH + 'px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'auto',
        opacity: 1,
        transition: 'all 250ms ease',
    };

    if (!updateAvailable && !success && !error) {
        styles = {
            ...styles,
            opacity: 0,
            margin: `10px -${NOTIFICATION_WIDTH}px 0 auto`,
        };
    }

    return styles;
};

export const hidden = (props) => Object.assign(notification(props), {});

export let text = {
    margin: '0 0 5px 0',
    fontSize: '12px',
    maxHeight: 'fit-content',
    color: COLORS.gray,
    fontWeight: 500,
};

export let dismiss = {
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: '11px',
    color: COLORS.grayFont,
};

export let iconContainer = {
    minHeight: '20px',
    minWidth: '20px',
    marginRight: '10px',
};
