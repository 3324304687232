import {newCOLORS} from 'core/styles';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    display: flex;
    padding: 12px 8px;
    align-items: center;
    font-weight: 500;
    border-radius: 8px;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: ${newCOLORS.gray200};
    }

    &.active {
        background-color: ${newCOLORS.primary};
    }

    .link-text {
        color: ${newCOLORS.gray400};
        font-weight: 500;
        text-decoration: none;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        margin-left: 12px;
    }

    &.active .link-text {
        color: ${newCOLORS.primary300};
    }
`;
