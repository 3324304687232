import {newCOLORS} from 'core/styles';
import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    padding: 30px 0;
`;

export const UserName = styled.div`
    padding: 0 8px;
    max-width: 100%;
    overflow: hidden;
    font-weight: bold;
    color: ${newCOLORS.gray400};
`;

export const UserRole = styled.div`
    padding: 0 8px;
    color: ${newCOLORS.gray400};
    font-weight: 500;
`;

export const Account = styled.div`
    padding: 8px;
    border-radius: 8px;
    color: ${newCOLORS.gray400};
    font-weight: 500;
    cursor: ${(props) => (props.hasSubaccounts ? 'pointer' : 'auto')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: ${(props) => (props.hasSubaccounts ? newCOLORS.gray200 : 'none')};
    }
`;

export const SubaccountsList = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
`;

export const AccountLink = styled(Link)`
    padding: 8px;
    border-radius: 8px;
    color: ${(props) => (props.isActive ? newCOLORS.primary300 : newCOLORS.gray400)};
    background-color: ${(props) => (props.isActive ? newCOLORS.primary : 'none')};
    transition: background-color 0.5s ease;

    &:hover {
        background-color:${(props) => (props.isActive ? 'none' : newCOLORS.gray200)};
    }
`;
