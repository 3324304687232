import React from 'react';
import propTypes from 'prop-types';

export default function IconHomeOutline({
    width = '24',
    height = '24',
    fill = '#000',
    rotate = '0',
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            style={{transform: `rotate(${rotate}deg)`}}>
            <path
                d="M19 21H5C4.44772 21 4 20.5523 4 20V12H2L11.292 2.707C11.4796 2.51922 11.7341 2.41371 11.9995 2.41371C12.2649 2.41371 12.5194 2.51922 12.707 2.707L22 12H20V20C20 20.5523 19.5523 21 19 21ZM10 14H14V19H18V10.828L12 4.828L6 10.828V19H10V14Z"
                fill={fill}
            />
        </svg>
    );
}

IconHomeOutline.propTypes = {
    width: propTypes.string,
    height: propTypes.string,
    fill: propTypes.string,
    rotate: propTypes.string,
};
